.dash {
   // background: rgb(242, 196, 125);
    //background: linear-gradient(115deg, rgba(242, 196, 125, 1) 0%, rgba(246, 219, 180, 1) 50%, rgba(249, 241, 229, 0.529936974789916) 100%);
    color: #13005A;
    width: 82%;
    height: 44.5em;
    position: absolute;
    //right: 30px;
    margin-top: 10px;
    // padding: 10px;
    right: 0px;
    border-radius: 10px 0px 0px 10px;
    -moz-border-radius: 10px 0px 0px 10px;
    -webkit-border-radius: 10px 0px 0px 10px;
    border: 0px solid #000000;
    // -webkit-box-shadow: -28px -23px 13px -22px rgba(102, 98, 102, 1);
    // -moz-box-shadow: -28px -23px 13px -22px rgba(102, 98, 102, 1);
    // box-shadow: -28px -23px 13px -22px rgba(102, 98, 102, 1);
    overflow-x: hidden;

    h4 {
        font-size: 30px;
        font-weight: 800;
        margin-top: 1em;
        margin-left: 30px;
    }

    
    .tableSection {
        //display:block;
        //align-items:center;
        //justify-content:center; 
        margin-top: 30px;
        height: 600px;
        //background-color: lightseagreen;
        //width:100%;
        overflow-y: scroll;
        margin-left: 30px;
        //margin-right: 15px;
        border-radius: 15px 15px 0px 0px;
        -moz-border-radius: 15px 15px 0px 0px;
        -webkit-border-radius: 15px 15px 0px 0px;
        border: 0px solid #000000;
        -webkit-box-shadow: 0px -6px 7px -3px rgba(184, 184, 184, 1);
        -moz-box-shadow: 0px -6px 7px -3px rgba(184, 184, 184, 1);
        box-shadow: 0px -6px 7px -3px rgba(184, 184, 184, 1);
        width:95%;
        &::-webkit-scrollbar {
            width: 0px;
        }

        table {
            border-collapse: collapse;
            background-color: rgba(248, 248, 248, 0.796);
            width: 100%;

        }

        thead,
        th {
            position: sticky;
            top: 0;
        }

        th,
        td {
            padding: 7px 12px;
            border-left: none;
            border-right: none;
            font-weight: 300;
            border-bottom: 2px solid black;
            
            
        }

        th {
            background-color: white;
            font-weight: 500;
        }

    }
}